import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import {useEffect, useState} from "react";
import {useDoc} from "../../hooks/firestore";


export default function MailPage(){
  const [regexp, setRegexp] = useState<string>('');
  const [id, setId] = useState<string>('');
  const {data:mail} = useDoc<any>('mail', id);

  console.log(mail);

  return(
    <Grid container xs={12}>
      <Grid item xs={2}>
        <TextField fullWidth value={id} onChange={(e)=>setId(e.target.value)}/>
      </Grid>
      <Grid item xs={10}>
        <TextField fullWidth value={regexp} onChange={(e)=>setRegexp(e.target.value)}/>
      </Grid>
      <Grid item xs={6}>
        <TextField fullWidth multiline value={mail?.text ??''}/>
      </Grid>
      <Grid item xs={6}>
        <div dangerouslySetInnerHTML={{__html:mail?.html ?? ''}}/>
      </Grid>
    </Grid>
  )
}